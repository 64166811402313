
/* eslint-disable @typescript-eslint/camelcase */
import { Options, Vue, prop } from "vue-class-component";
import Tooltip from "@/app/ui/components/tooltip/index.vue";
import formatPriceIDR from "@/app/infrastructures/misc/common-library/FormatPriceIDR";
import { CustomProcessEditAddressForm } from "@/domain/entities/CustomProcess";
import { BookingController } from "@/app/ui/controllers/BookingController";
import { DistrictData } from "@/domain/entities/Location";
import { CustomProcessController } from "@/app/ui/controllers/CustomProcessController";
import { AccountController } from "@/app/ui/controllers/AccountController";
import { Commodity } from "@/domain/entities/Commodity";
import { ProductDeliveryData } from "@/domain/entities/Booking";

class Props {
  disabledSubmit = prop<boolean>({
    default: false,
    type: Boolean
  });
  form = prop<CustomProcessEditAddressForm>({
    default: new CustomProcessEditAddressForm({})
  });
  posIDForInternal = prop<string>({
    default: "",
    type: String
  })
}

@Options({
  emits: ["updateStatusErrorEmbargo", "onSubmit"],
  components: {
    Tooltip
  },
  directives: {
    "click-outside": {
      beforeMount: (el: any, binding: any) => {
        const ourClickEventHandler = (event: any) => {
          if (!el.contains(event.target) && el !== event.target) {
            binding.value(event);
          }
        };
        el.__vueClickEventHandler__ = ourClickEventHandler;
        document.addEventListener("click", ourClickEventHandler);
      },
      unmounted: (el: any) => {
        document.removeEventListener("click", el.__vueClickEventHandler__);
      }
    }
  }
})
export default class Estimation extends Vue.with(Props) {
  isShow = false;

  mounted() {
    this.getEstimationPrice();
  }

  unmounted() {
    BookingController.setDefaultEstimationPrice();
  }

  showDetail() {
    this.isShow = !this.isShow;
  }

  get shipmentDetail() {
    return CustomProcessController.shipmentDetail;
  }

  get dataProfile() {
    return CustomProcessController.dataProfile;
  }

  get dataDetail() {
    return BookingController.estimationPrice;
  }

  isOpenTooltip = false;
  onOpenTooltip(value: boolean) {
    this.isOpenTooltip = value;
  }

  onCloseAnywhere(value: boolean) {
    this.isShow = value;
  }

  currency(curr: number) {
    return formatPriceIDR(curr);
  }

  getEstimationPrice() {
    if (!this.disabledSubmit) {
      const destination: DistrictData = this.form
        .destinationCity as DistrictData;
      return CustomProcessController.getEstimationPrice({
        originId: this.form.statusStt === "MISBOOKING" && this.isInternal ? this.posIDForInternal : AccountController.accountData.account_location.district_code,
        destinationId: destination.code,
        commodityId:
          (this.form.commodity as Commodity).commodity_id ||
          this.shipmentDetail.sttCommodityId,
        productType:
          (this.form.product as ProductDeliveryData).name ||
          String(this.form.product) ||
          this.shipmentDetail.sttProductTypeName,
        accountType: this.shipmentDetail.sttBookedForType,
        accountRefId: this.shipmentDetail.sttBookedForId,
        goodsPrice: +this.shipmentDetail.sttGoodsEstimatePrice.replace(
          /\./g,
          ""
        ),
        insuranceType: this.shipmentDetail.sttInsuranceName,
        isWoodpacking: this.shipmentDetail.sttIsWoodPacking,
        isHaveTaxId:
          !!this.form.taxNumber || !!this.shipmentDetail.sttTaxNumber,
        pieces: this.shipmentDetail.SttPieces,
        isCod: false,
        codAmount: 0
      });
    }
    return false;
  }

  async onSave() {
    const resp = await this.getEstimationPrice();
    if (resp) this.$emit("onSubmit");
  }

  get isInternal() {
    return AccountController.accountData.isInternalAccount;
  }
}
